<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.bookkeepingManagement') }}</h1>
      <p v-if="!loading && sentDate" class="fw-bold">
        {{ $t('bookkeeping.sentDocuments.subTitle', { date: formatDate(sentDate) }) }}
      </p>
    </template>

    <div v-if="loading" v-loading="loading" style="height: 50vh" />
    <template v-else>
      <div class="d-flex justify-content-end">
        <Button
          class="mb-5"
          :disabled="batch.sendingMethod === SENDING_METHOD.PLATFORM"
          @click="resendModalOpen = true"
        >
          {{ $t('bookkeeping.sendModal.openButton.resend') }}
        </Button>
      </div>
      <BillingBatch v-if="isBillingBatch" :billings="billings" />
      <PaymentBatch v-if="isPaymentBatch" :payments="payments" />
      <DocumentBatch v-if="isDocumentBatch" :documents="documents" />
    </template>
    <DocumentSendingModal
      v-if="resendModalOpen"
      :type="batch.type"
      :docs="batch.refs"
      :send-email="sendEmail"
      :from-date="documentsPeriod.fromDate"
      :to-date="documentsPeriod.toDate"
      :resend="true"
      @close="resendModalOpen = false"
      @send="handleResendEvent"
    />
  </PageLayout>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil } from 'ramda';

import { useTenancy } from '@/modules/auth';
import { PageLayout, Button } from '@/modules/core';

import { useConfigs } from './composition/config';
import { useBatch, useBatchResend } from './composition/batch';
import { useBillings } from './composition/billing';
import { usePayments } from './composition/payment';
import { formatDate } from './formatters';
import { BATCH_TYPE } from './batch';
import DocumentSendingModal from './components/DocumentSendingModal';
import { BillingBatch, PaymentBatch, DocumentBatch } from './components';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { SENDING_METHOD } from './constants';

export default {
  components: { PageLayout, DocumentSendingModal, Button, BillingBatch, PaymentBatch, DocumentBatch },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { configs, loading: configsLoading } = useConfigs(computed(() => currentTenant.value.id));
    const { batch } = useBatch(computed(() => ({ id: root.$route.params.id })));

    const { resendBatch, loading: resending, onDone, onError } = useBatchResend();

    onDone(() => root.$message.success(root.$t('bookkeeping.sendModal.sendMessages.success')));
    onError(() => root.$message.error(root.$t('bookkeeping.sendModal.sendMessages.error')));

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: batch.value?.type === BATCH_TYPE.BILLING ? batch.value.refs : [],
        businessId: currentTenant.value.id,
      }))
    );

    const { payments, loading: paymentsLoading } = usePayments(
      computed(() => ({
        ids: batch.value?.type === BATCH_TYPE.PAYMENT ? batch.value.refs : [],
        businessId: currentTenant.value.id,
      }))
    );

    const { documents, loading: documentsLoading } = useDocumentsByIds(
      computed(() => ({
        ids: batch.value?.type === BATCH_TYPE.DOCUMENT ? batch.value.refs : [],
        businessId: currentTenant.value.id,
      }))
    );

    return {
      SENDING_METHOD,
      batch,
      billings,
      billingsLoading,
      payments,
      paymentsLoading,
      documents,
      documentsLoading,
      loading: computed(() => {
        let loading;
        switch (batch.value?.type) {
          case BATCH_TYPE.BILLING:
            loading = !billings.value.length;
            break;
          case BATCH_TYPE.PAYMENT:
            loading = !payments.value.length;
            break;
          case BATCH_TYPE.DOCUMENT:
            loading = !documents.value.length;
            break;
          default:
            loading = true;
            break;
        }
        return configsLoading.value || loading;
      }),
      configs: computed(() => configs.value[0]),
      resendModalOpen: ref(false),
      resendBatch,
      resending,
    };
  },
  computed: {
    isBillingBatch() {
      return this.batch?.type === BATCH_TYPE.BILLING;
    },
    isPaymentBatch() {
      return this.batch?.type === BATCH_TYPE.PAYMENT;
    },
    isDocumentBatch() {
      return this.batch?.type === BATCH_TYPE.DOCUMENT;
    },
    sentDate() {
      return this.batch?.createdAt;
    },
    documentsPeriod() {
      const dateType = this.isDocumentBatch ? 'issueDate' : 'date';
      switch (this.batch?.type) {
        case BATCH_TYPE.BILLING: {
          return this.getPeriod(this.billings, dateType);
        }
        case BATCH_TYPE.PAYMENT: {
          return this.getPeriod(this.payments, dateType);
        }
        case BATCH_TYPE.DOCUMENT: {
          return this.getPeriod(this.documents, dateType);
        }
        default:
          return {
            fromDate: null,
            toDate: null,
          };
      }
    },
    sendEmail() {
      switch (this.batch?.type) {
        case BATCH_TYPE.BILLING:
          return this.configs.billingSending.email;
        case BATCH_TYPE.PAYMENT:
          return this.configs.paymentSending.email;
        case BATCH_TYPE.DOCUMENT:
          return this.configs.documentSending.email;
        default:
          return null;
      }
    },
    bookkeeperContact() {
      return this.configs.bookkeeper
        ? `${this.configs.bookkeeper.firstName} ${this.configs.bookkeeper.lastName}`
        : null;
    },
  },
  methods: {
    formatDate,
    async handleResendEvent({ email }) {
      await this.resendBatch(reject(isNil, { id: this.batch.id, email }));
      this.resendModalOpen = false;
    },
    getPeriod(docs, dateType) {
      const docsSortedByDate = [...docs].sort((a, b) => new Date(b[dateType]) - new Date(a[dateType]));
      const mostRecentDocument = docsSortedByDate[0];
      const leastRecentDocument = docsSortedByDate[docsSortedByDate.length - 1];

      return {
        fromDate: DateTime.fromJSDate(new Date(leastRecentDocument[dateType])).toMillis(),
        toDate: DateTime.fromJSDate(new Date(mostRecentDocument[dateType])).toMillis(),
      };
    },
  },
};
</script>
