<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.bookkeepingManagement') }}</h1>
    </template>
    <template #dashboard>
      <div class="d-flex gap-5">
        <BookkeepingDetails class="w-50" :config="config" :loading="configsLoading" />
        <SendingConfig
          class="w-100"
          :config="config"
          :latest-billings-sending="latestBillingBatchSent"
          :latest-payments-sending="latestPaymentBatchSent"
          :latest-documents-sending="latestDocumentBatchSent"
          :loading="anyLoading"
        />
      </div>
    </template>
    <template #tabs>
      <Tabs :tabs="typeTabs" :active-tab.sync="activeTypeTab" />
      <Tabs v-if="activeTypeTab < 3" :tabs="innerTabs" :active-tab.sync="activeInnerTab" />
    </template>
    <UnsentBillings
      v-show="activeInnerTab === 0 && activeTypeTab === 0"
      :billings="unsentBillings"
      :loading="unsentBillingsLoading"
      :config="config"
      @batch-sent="billingsRefetch"
    />
    <SentBillingBatches v-show="activeInnerTab === 1 && activeTypeTab === 0" :billing-batches="billingBatches" />
    <UnsentPayments
      v-show="activeInnerTab === 0 && activeTypeTab === 1"
      :payments="unsentPayments"
      :loading="unsentPaymentsLoading"
      :config="config"
      @batch-sent="paymentsRefetch"
    />
    <SentPaymentBatches v-show="activeInnerTab === 1 && activeTypeTab === 1" :payment-batches="paymentBatches" />
    <UnsentRawDocuments
      v-show="activeInnerTab === 0 && activeTypeTab === 2"
      :documents="unsentDocuments"
      :loading="unsentDocumentsLoading"
      :config="config"
      @batch-sent="documentsRefetch"
    />
    <SentDocumentBatches v-show="activeInnerTab === 1 && activeTypeTab === 2" :document-batches="documentBatches" />
  </PageLayout>
</template>

<script>
import { uniq } from 'ramda';
import { computed, ref } from 'vue';

import { PageLayout, Tabs } from '@/modules/core';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { useUser, useTenancy } from '@/modules/auth';

import {
  BookkeepingDetails,
  SendingConfig,
  SentBillingBatches,
  SentPaymentBatches,
  SentDocumentBatches,
  UnsentBillings,
  UnsentPayments,
  UnsentRawDocuments,
} from './components';
import { useBatches } from './composition/batch';
import { useConfigs } from './composition/config';
import { useUnsentDocuments } from './composition/unsentDocument';
import { useBillings } from './composition/billing';
import { usePayments } from './composition/payment';
import { BATCH_TYPE } from './batch';

export default {
  components: {
    PageLayout,
    BookkeepingDetails,
    SendingConfig,
    Tabs,
    SentBillingBatches,
    SentPaymentBatches,
    SentDocumentBatches,
    UnsentBillings,
    UnsentPayments,
    UnsentRawDocuments,
  },
  setup() {
    const { currentTenant } = useTenancy();
    const queryOptions = computed(() => ({ enabled: Boolean(currentTenant.value?.id) }));
    const { configs, loading: configsLoading } = useConfigs(computed(() => currentTenant.value.id));
    const { isAdmin } = useUser();

    const {
      batches: billingBatches,
      loading: billingBatchesLoading,
      refetch: billingBatchesRefetch,
    } = useBatches(
      computed(() => ({ type: BATCH_TYPE.BILLING, businessId: currentTenant.value?.id })),
      queryOptions
    );

    const {
      unsentDocuments: unsentBillingsRefs,
      loading: unsentBillingsRefLoading,
      refetch: unsentBillingsRefRefetch,
    } = useUnsentDocuments(
      computed(() => ({ type: BATCH_TYPE.BILLING, businessId: currentTenant.value?.id })),
      queryOptions
    );
    const { billings: unsentBillingsWithNonRegulatory, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: unsentBillingsRefs.value.map(({ ref: billingRef }) => billingRef),
        businessId: currentTenant.value.id,
      }))
    );

    const {
      batches: paymentBatches,
      loading: paymentBatchesLoading,
      refetch: paymentBatchesRefetch,
    } = useBatches(
      computed(() => ({ type: BATCH_TYPE.PAYMENT, businessId: currentTenant.value?.id })),
      queryOptions
    );

    const {
      unsentDocuments: unsentPaymentsRefs,
      totalCount: unsentPaymentsCount,
      loading: unsentPaymentsRefLoading,
      refetch: unsentPaymentsRefetch,
    } = useUnsentDocuments(
      computed(() => ({ type: BATCH_TYPE.PAYMENT, businessId: currentTenant.value?.id })),
      queryOptions
    );
    const { payments: unsentPayments, loading: paymentsLoading } = usePayments(
      computed(() => ({
        ids: unsentPaymentsRefs.value.map(({ ref: billingRef }) => billingRef),
        businessId: currentTenant.value.id,
      }))
    );

    const {
      batches: documentBatches,
      loading: documentBatchesLoading,
      refetch: documentBatchesRefetch,
    } = useBatches(
      computed(() => ({ type: BATCH_TYPE.DOCUMENT, businessId: currentTenant.value?.id })),
      queryOptions
    );
    const {
      unsentDocuments: unsentDocumentsRefs,
      totalCount: unsentDocumentsCount,
      loading: unsentDocumentsRefLoading,
      refetch: unsentDocumentsRefRefetch,
    } = useUnsentDocuments(
      computed(() => ({ type: BATCH_TYPE.DOCUMENT, businessId: currentTenant.value?.id })),
      queryOptions
    );
    const { documents: unsentDocuments, loading: documentsLoading } = useDocumentsByIds(
      computed(() => ({
        ids: uniq(unsentDocumentsRefs.value.map(({ ref }) => ref)),
        businessId: currentTenant.value.id,
      }))
    );

    const unsentBillings = computed(() =>
      unsentBillingsRefs.value.length
        ? currentTenant.value.countryCode === 'IL'
          ? unsentBillingsWithNonRegulatory.value.filter((b) => b.eventReferences.find((doc) => doc.regulatory))
          : unsentBillingsWithNonRegulatory.value
        : []
    );

    return {
      isAdmin,
      activeTypeTab: ref(0),
      activeInnerTab: ref(0),
      config: computed(() => configs.value[0] ?? {}),
      configsLoading,
      unsentBillings,
      unsentBillingsCount: computed(() => unsentBillings.value.length),
      unsentBillingsLoading: computed(() => billingsLoading.value || unsentBillingsRefLoading.value),
      unsentPayments: computed(() => (unsentPaymentsRefs.value.length ? unsentPayments.value : [])),
      unsentPaymentsCount,
      unsentPaymentsLoading: computed(() => paymentsLoading.value || unsentPaymentsRefLoading.value),
      unsentDocuments: computed(() => (unsentDocumentsRefs.value.length ? unsentDocuments.value : [])),
      unsentDocumentsCount,
      unsentDocumentsLoading: computed(() => documentsLoading.value || unsentDocumentsRefLoading.value),
      documentBatches,
      documentBatchesLoading,
      latestDocumentBatchSent: computed(() => {
        const latest = [...documentBatches.value].sort((a, b) => b.createdAt - a.createdAt)[0];
        return latest ? new Date(latest.createdAt) : null;
      }),
      billingBatches,
      billingBatchesLoading,
      latestBillingBatchSent: computed(() => {
        const latest = [...billingBatches.value].sort((a, b) => b.createdAt - a.createdAt)[0];
        return latest ? new Date(latest.createdAt) : null;
      }),
      paymentBatches,
      paymentBatchesLoading,
      latestPaymentBatchSent: computed(() => {
        const latest = [...paymentBatches.value].sort((a, b) => b.createdAt - a.createdAt)[0];
        return latest ? new Date(latest.createdAt) : null;
      }),
      billingsRefetch() {
        unsentBillingsRefRefetch();
        billingBatchesRefetch();
      },
      paymentsRefetch() {
        unsentPaymentsRefetch();
        paymentBatchesRefetch();
      },
      documentsRefetch() {
        unsentDocumentsRefRefetch();
        documentBatchesRefetch();
      },
    };
  },
  computed: {
    totalUnsent() {
      return this.unsentBillingsCount + this.unsentPaymentsCount;
    },
    anyLoading() {
      return this.paymentBatchesLoading || this.billingBatchesLoading || this.documentBatchesLoading;
    },
    typeTabs() {
      return [
        {
          text: this.$t('bookkeeping.bookkeepingManagement.tabs.billings'),
        },
        {
          text: this.$t('bookkeeping.bookkeepingManagement.tabs.payments'),
        },
        {
          text: this.$t('bookkeeping.bookkeepingManagement.tabs.documents'),
        },
      ];
    },
    innerTabs() {
      switch (this.activeTypeTab) {
        case 0:
          return [
            {
              text: this.$t('bookkeeping.bookkeepingManagement.tabs.unsent'),
              badgeValue: this.unsentBillingsCount,
            },
            {
              text: this.$t('bookkeeping.bookkeepingManagement.tabs.sent'),
              badgeValue: this.billingBatches.length,
            },
          ];
        case 1:
          return [
            {
              text: this.$t('bookkeeping.bookkeepingManagement.tabs.unsent'),
              badgeValue: this.unsentPaymentsCount,
            },
            {
              text: this.$t('bookkeeping.bookkeepingManagement.tabs.sent'),
              badgeValue: this.paymentBatches.length,
            },
          ];
        case 2:
          return [
            {
              text: this.$t('bookkeeping.bookkeepingManagement.tabs.unsent'),
              badgeValue: this.unsentDocumentsCount,
            },
            {
              text: this.$t('bookkeeping.bookkeepingManagement.tabs.sent'),
              badgeValue: this.documentBatches.length,
            },
          ];

        default:
          return [];
      }
    },
  },
  watch: {
    activeTypeTab() {
      this.activeInnerTab = 0;
    },
  },
};
</script>
