export const STATUSES = {
  PROCESSING: 'processing',
  SENT: 'sent',
  DELIVERED: 'delivered',
  SEEN: 'seen',
  FILE_DOWNLOADED: 'fileDownloaded',
  FAILED: 'failed',
};

export const getStatusColor = (status) => {
  switch (status) {
    case STATUSES.PROCESSING:
    case STATUSES.SENT:
    case STATUSES.DELIVERED:
      return 'info';
    case STATUSES.SEEN:
    case STATUSES.FILE_DOWNLOADED:
      return 'success';
    case STATUSES.FAILED:
      return 'danger';
  }
};

export const BATCH_TYPE = {
  BILLING: 'billing',
  PAYMENT: 'payment',
  DOCUMENT: 'document',
};
